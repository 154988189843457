import React from "react"
import Layout from "../../components/member/Layout"
import calendar from "../../components/member/Calendar/Calendar"

export default props => {
  const pageSlug = "events"
  const pageTitle = "イベント"
  var Calendar = calendar("event")
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <Calendar />
    </Layout>
  )
}
